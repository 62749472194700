<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="종합소견 정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-3">
            <c-select
              :comboItems="Items1"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="col2"
              label="업무수행적합"
              v-model="data.col2"
            ></c-select>
          </div>
          <div class="col-3">
            <c-select
              :comboItems="Items2"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="col3"
              label="사후관리"
              v-model="data.col3"
            ></c-select>
          </div>
          <div class="col-6">
            <c-text
              label="종합소견"
              name="col4"
              v-model="data.col4">
            </c-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <c-table
        ref="table1"
        title="소견 결과"
        tableId="table1"
        :columns="grid1.columns"
        :gridHeight="grid1.height"
        :data="grid1.data"
        :columnSetting="false"
        :isFullScreen="false"
        :expandAll="true"
        selection="multiple"
        rowKey="col3"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLADD" icon="add" />
            <c-btn label="LBLEXCEPT" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'check-up-opinion',
  props: {
    data: {
      type: Object,
      default: () => ({
        checkUpId: '',
        opinionList: [],
      }),
    },
  },
  data() {
    return {
      editable: false,
      Items1: [
        { code: '1', codeName: '현재조건 작업가능' },
        { code: '2', codeName: '일장조건 작업가능' },
      ],
      Items2: [
        { code: '1', codeName: '필요없음' },
        { code: '2', codeName: '건강상담' },
      ],
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '판정',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '질환종류',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '질환',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: 'LBLREMARK',
            align: 'left',
            sortable: false,
          },
        ],
        height: '330px',
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getDetail();
    },
    getDetail() {
      this.grid1.data = [
        {
          col1: 'D1', 
          col2: '소견', 
          col3: '식도역류증', 
          col4: '', 
        },
      ]
    },
  }
};
</script>