var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "종합소견 정보" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-select", {
                    attrs: {
                      comboItems: _vm.Items1,
                      type: "edit",
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col2",
                      label: "업무수행적합",
                    },
                    model: {
                      value: _vm.data.col2,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col2", $$v)
                      },
                      expression: "data.col2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-select", {
                    attrs: {
                      comboItems: _vm.Items2,
                      type: "edit",
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col3",
                      label: "사후관리",
                    },
                    model: {
                      value: _vm.data.col3,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col3", $$v)
                      },
                      expression: "data.col3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("c-text", {
                    attrs: { label: "종합소견", name: "col4" },
                    model: {
                      value: _vm.data.col4,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col4", $$v)
                      },
                      expression: "data.col4",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-table",
          {
            ref: "table1",
            attrs: {
              title: "소견 결과",
              tableId: "table1",
              columns: _vm.grid1.columns,
              gridHeight: _vm.grid1.height,
              data: _vm.grid1.data,
              columnSetting: false,
              isFullScreen: false,
              expandAll: true,
              selection: "multiple",
              rowKey: "col3",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", { attrs: { label: "LBLADD", icon: "add" } }),
                    _c("c-btn", {
                      attrs: { label: "LBLEXCEPT", icon: "remove" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }